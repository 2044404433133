<template>
  <div>
    <!-- Header -->
    <MainHeader />
    <!-- end Header -->

    <!-- Main Banner -->
    <MainBanner hide-enquiry="true" />
    <!-- end Main Banner -->
    <div class="py-10">
      <div class="container">
        <div class="text-center">
          <h1
            class="pb-6 text-3xl font-bold text-center text-color-orange md:text-4xl"
          >
            Message Sending Successful!
          </h1>
          <p class="px-4 text-lg text-gray-800 md:px-0">
            Thank you for your kind interest in Color Dot Com Sdn Bhd. We will
            get back to you as soon as possible.
          </p>
          <router-link to="/" class="block w-48 mx-auto bg-gray-800 rounded-lg">
            <p class="block py-2 my-5 text-color-orange">Back To Home</p>
          </router-link>
        </div>
      </div>
    </div>
    <!-- copyRight -->
  </div>
</template>

<script>
import MainHeader from "@/components/MainHeader.vue";
import MainBanner from "@/components/MainBanner.vue";

export default {
  components: {
    MainHeader,
    MainBanner,
  },
};
</script>

<style>
</style>